.inputWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 0 10px 0;
  min-width: 200px;
}

.label {
  font-size: 14px;
  color: #524840;
}

.optionalIndicator {
  color: #bc0046;
}

.input {
  background: none;
  font-size: 14px;
  border: none;
  border-bottom: 1px solid #87776a;
  padding: 14px 0 8px 0;
  box-sizing: border-box;
  transition: 0.5s background-color;
}

.input::placeholder {
  color: #75685d;
}

.input:disabled {
  background-color: #e0ded7;
  opacity: 0.8;
  transition: 0.5s background-color;
}

.errorContainer {
  margin: 0;
  list-style: none;
  padding: 0;
}

.error {
  color: crimson;
  padding: 5px 0;
}
