.disclaimer {
  display: flex;
}

.disclaimerText {
  padding: 0 1rem;
}

.disclaimerLink {
  color: #bc0046;
  text-decoration: underline;
}
