/* FOOTER */

/* Phone */
a {
  color: white;
  text-decoration: none;
}

.footer {
  width: 100%;
  flex: 0 1 auto;
}
.footerBottom {
  padding-top: 20px;
  padding-bottom: 20px;
  background: #bfdbf5;
  padding-left: 14px;
}
.footerTop {
  color: #ffffff;
  padding-top: 30px;
  padding-bottom: 20px;
  padding-right: 14px;
  text-align: right;
  background: #211f57;
}
.footerIcon {
  margin-left: 16px;
  height: 48px;
  width: 48px;
}

/* Desktop */
@media screen and (min-width: 600px) {
  .footerIcons {
    width: 180px;
  }
  .footerIcon {
    margin-left: 8px;
    height: 48px;
    width: 48px;
  }
}
