.buttonText {
  display: none;
}

@media screen and (min-width: 600px) {
  .buttonText {
    display: inline-block;
    margin: 0 1rem;
  }
}
