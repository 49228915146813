/* START Component */

/* Phone */
.result {
  background: #f0eee7;
  color: #211f57;
}
.textArea {
  padding: 10px;
}

.description {
  display: flex;
  justify-content: space-between;
}
.subTitle {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  margin-bottom: 4px;
}
.primaryResult {
  border: 2px solid #211f57;
  box-sizing: border-box;
  border-radius: 50%;
  height: 70px;
  min-width: 70px;
  position: relative;
  font-size: 40px;
}
.primaryDescription {
  margin-left: 16px;
}
.primaryResultDescription {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
}
.subResult {
  display: flex;
}
.subValue {
  border-radius: 50%;
  border-style: none;
  border: 1px solid #211f57;
  background: #f0eee7;
  font-size: 24px;
  margin: 5px;
  position: relative;
  height: 45px;
  width: 45px;
}
.subDescription {
  margin-left: 10px;
}
.subText {
  font-size: 12px;
}

.number {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.rightArrow {
  border: 1px solid #bc0046;
  background: #bc0046;
  border-radius: 50%;
  color: #f0eee7;
  padding: 20px;
}

.leftArrow {
  border: 1px solid #bc0046;
  box-sizing: border-box;
  background: #f0eee7;
  border-radius: 50%;
  padding: 20px;
}

/* Desktop */
@media screen and (min-width: 600px) {
}
