.final {
  width: 100%;
  height: 100%;
  background: #f0eee7;
  color: #211f57;
  box-sizing: border-box;
  padding: 2rem;
}

.titleMsg {
  margin: 0;
  padding: 0;
  font-size: 4rem;
  max-width: 600px;
}

.actionsRow {
  box-sizing: border-box;
  padding: 2rem 0;
}

@media screen and (min-width: 300px) {
  .titleMsg {
    font-size: 2rem;
  }
}
