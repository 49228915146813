@keyframes wobble {
  from {
    transform: translateY(1rem);
  }
  to {
    transform: translateY(-1rem);
  }
}

.loadingIndicator {
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: wobble 1s alternate infinite;
  padding: 5rem;
}

.loadingScreen {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #f0eee7;
}
