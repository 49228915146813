/* START Component */

/* Phone */
.start {
  background: #f0eee7;
  color: #211f57;
  height: 100%;
}
.textArea {
  padding: 40px;
  max-width: 800px;
}
.title {
  font-size: 48px;
  font-weight: 500;
}
.subTitle {
  margin-top: 12px;
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
}
.rawText {
  margin-top: 12px;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
}

.shortText {
  margin-top: 12px;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
}

.button {
  max-width: 300px;
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  text-align: right;
  line-height: 30px;
  background: #bc0046;
  border-radius: 100px;
  color: #f0eee7;
  padding: 20px;
  border: none;
}
.buttonIcon {
  margin-left: 0px;
}

/* Desktop */
@media screen and (min-width: 600px) {
  .button {
    font-size: 24px;
  }
}
