.title {
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 30px;
  margin-bottom: 10px;
}

.titleDescription {
  font-size: 18px;
  max-width: 600px;
}
