/* START Component */

/* Phone */
.question {
  background: #f0eee7;
  color: #211f57;
}
.textArea {
  padding: 10px 0;
}
.title {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
}
.titleDescription {
  margin-top: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
}
.questionNumber {
  margin-top: 10px;
  margin-bottom: 6px;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
}
.questionText {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  margin-bottom: 10px;
}
.questionAnswers {
  display: flex;
  justify-content: center;
  font-size: 12px;
}
.questionAnswer {
  font-weight: normal;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  text-align: center;
}

.buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.questionButton {
  cursor: pointer;
  border-radius: 50%;
  border-style: none;
  border: 1px solid #211f57;
  background: #f0eee7;
  font-size: 12px;
  margin: 5px;
  padding: 10px 14px;
}

.selectedButton {
  border-radius: 50%;
  border-style: none;
  border: 1px solid #211f57;
  background: #211f57;
  color: #ffffff;
  font-size: 24px;
  margin: 5px;
  padding: 7px 14px 7px 14px;
}

.rightArrow {
  cursor: pointer;
  border: 1px solid #bc0046;
  background: #bc0046;
  border-radius: 50%;
  color: #f0eee7;
  padding: 20px;
}

.leftArrow {
  cursor: pointer;
  border: 1px solid #bc0046;
  box-sizing: border-box;
  background: #f0eee7;
  border-radius: 50%;
  padding: 20px;
}

/* Desktop */
@media screen and (min-width: 600px) {
  .questionButton {
    font-size: 24px;
    margin: 5px;
    padding: 7px 14px 7px 14px;
  }
}
