.button,
.frameButton {
  cursor: pointer;
  padding: 5px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button {
  border: none;
  background: #bc0046;
  border-radius: 2rem;
  font-weight: bold;
  color: #f0eee7;
}

.frameButton {
  border: 2px solid #bc0046;
  background: none;
  color: #bc0046;
  border-radius: 2rem;
  font-weight: bold;
  padding: 10px;
}

.frameButton:disabled,
.button:disabled {
  cursor: initial;
  opacity: 0.5;
}

@media screen and (min-width: 300px) {
  .button,
  .frameButton {
    padding: 20px;
    font-size: 18px;
  }
}
