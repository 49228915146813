/* HEADER */

/* Phone */
.header {
  width: 100%;
  flex: 0 1 auto;
}
.headerTitle {
  width: 150px;
  margin: auto;
  background: #ffffff;
}
.headerLogo {
  margin-top: 20px;
  width: 150px;
  height: 50px;
}
.headerProgress {
  background: #211f57;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
  color: #ffffff;
}

/* Desktop */
@media screen and (min-width: 600px) {
  .header {
    width: 100%;
    margin-top: 1vw;
  }
  .headerLogo {
    margin-top: 20px;
    width: 150px;
    height: 50px;
  }
}
